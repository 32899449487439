<template>
<div class="wrap">
    <div class="mg-auto" style="width: 1250px;">
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> EAP상담수정</h2>

            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">기업명 <span class="red">*</span></th>
                                <td class="left">
                                    <div id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                        <div class="search">
                                            <input type="text" v-model="companyName" class="ml-20 w-200px" id="company" name="company" @input="watchEvent($event, 'company')">
                                            <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCompany }" v-on:mouseover="removeValue">
                                                <li tabindex="-1" v-for="(el, index) in filterCompanyList" v-on:click="changeValue(el.name, 'company', el.idx)" v-on:keyup.enter="selectValue('enter', el.name, el.idx)" :key="index">
                                                    <span>{{ el.name }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <th>고객명 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="name" class="ml-20 w-200px" disabled>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰 <span class="red">*</span></th>
                                <td class="left">
                                    <select class="ml-20" v-model="phone1" id="phone1" name="phone1">
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select> -
                                    <input type="text" style="width:100px;" v-model="phone2" id="phone2" name="phone2" value=""> -
                                    <input type="text" style="width:100px;" v-model="phone3" id="phone3" name="phone3" value="">
                                </td>
                                <th>이메일 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="email1" style="width:120px;" class="ml-20" name="email1" id="email1" value="">
                                    @<input type="text" v-model="email2" style="width:80px;" class="mg5" id="email2" name="email2" value="">
                                    <select v-model="email3" name="email3" id="email3" @change="email2 = email3">
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="naver.com">naver.com</option>
                                        <option value="nate.com">nate.com</option>
                                        <option value="nate.com">daum.net</option>
                                        <option value="" selected="selected">직접입력</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>희망지역 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <select v-model="eapHopeArea" @change="getCountyList()" class="w-200px  ml-20">
                                        <option value="">희망지역선택</option>
                                        <option v-for="(item, index) of cityList" :key="index" :value="item.city2">{{item.city}}</option>
                                    </select>
                                    <select v-model="eapHopeArea2" class="w-100px  ml-20">
                                        <option value="">상세지역</option>
                                        <option v-for="(item, index) of countyList" :key="index" :value="item.county">{{item.county}}</option>
                                    </select>
                                    <!-- <input type="text" v-model="zonecode" disabled class="pl-20 ml-20 w-200px">
                                    <button type="button" class="default-btn ml-20 mb-10" @click="onModal()">주소 검색</button> <br>
                                    <input type="text" v-model="defaultAddress" disabled class="pl-20 ml-20 mb-10 mr-10 w-400px">기본주소 <br>
                                    <input type="text" v-model="detailAddress" class="pl-20 ml-20 mr-10 w-400px">상세주소 -->
                                </td>
                            </tr>
                            <tr>
                                <th>희망일정 <span class="red">*</span></th>
                                <td class="left">
                                    <crm-datepicker class="ml-20" v-model="eapHopeDate" :parentDate="eapHopeDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                </td>
                                <th>사용가능회기 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="consCount" class="ml-20 w-60px"> 회
                                </td>
                            </tr>
                            <tr>
                                <th>상담유형 <span class="red">*</span></th>
                                <td class="left">
                                    <select v-model="idxCrmMetaType" class="w-200px  ml-20">
                                        <option value="-1">상담유형선택</option>
                                        <option v-for="(item, index) of typeList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td>
                                <th>상담주제 <span class="red">*</span></th>
                                <td class="left">
                                    <select v-model="idxCrmMetaSubject" class="w-200px ml-20">
                                        <option value="-1">상담주제선택</option>
                                        <option v-for="(item, index) of subjectList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>직원과의 관계</th>
                                <td class="left" colspan="3">
                                    <input type="text" v-model="eapRel" class="ml-20 w-800px">
                                </td>
                            </tr>
                            <tr>
                                <th>진행현황 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <select v-model="eapProcessStatus" class="ml-20 w-120px">
                                        <option value="의뢰">의뢰</option>
                                        <option value="종료">종료</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>메모</th>
                                <td class="left" colspan="3">
                                    <textarea type="text" v-model="memo" class="ml-20 w-98per h-100px" value="" />
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle mr-10 pointer" @click="putClientInfo()">수정</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <address-modal :isOnModal="isOnModal" @close="closeModal"></address-modal>
</div>
</template>

<script>
import AddressModal from '@/components/modal/searchAddress.vue'
import {
    IDFormatCheck,
    PWFormatCheck,
    EmptyCheck
} from '@/utils/validation.js'
import {
    GetUrlParams
} from '@/utils/common.js'

export default {
    components: {
        AddressModal: AddressModal,
    },
    data: () => ({
        isOnModal: false, // 주소 모달 on/off 여부

        zonecode: '', // 주소코드
        defaultAddress: '', // 기본주소
        detailAddress: '', // 상세주소

        email1: '', // 이메일 아이디 입력란
        email2: '', // 이메일 도메인 입력란
        email3: '', // 이메일 도메인 선택란

        typeList: [],
        subjectList: [],
        companyList: [], // 기업리스트
        isAutoCompany: false, // 소속(컴퍼니) 자동완성 on/off
        autoSearchCompany: '', // 소속(컴퍼니) 자동완성 검색
        companyName: '', // 기업명
        idxCrmCompany: -1,
        eapYn: 'Y',
        name: '',
        phone1: '010',
        phone2: '',
        phone3: '',
        email: '',
        eapHopeDate: '',
        eapHopeArea: '',
        eapHopeArea2: '',
        eapProcessStatus: '의뢰',
        memo: '',
        eapRel: '',
        idxCrmMetaSubject: -1,
        idxCrmMetaType: -1,
        consCount: 0,
        idx: -1,
        clientCode: '',
        idxCrmCenter: -1,
        idxCrmStaff: -1,
        cityList: [],
        countyList: [],
        gender: '',
        birth: '',
        email: '',
        idxCrmMetaRoute: -1,
        idxCrmPerson: -1,
        idxCrmClient: -1,
    }),

    computed: {
        filterCounselorList() {
            const str = this.autoSearchCounselor
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCounselor = true
                var filterNames = []
                filterNames = this.staffList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCounselor = false
                }
            } else {
                this.isAutoCounselor = false
            }
        },

        filterCompanyList() {
            const str = this.autoSearchCompany
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCompany = true
                var filterNames = []
                filterNames = this.companyList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCompany = false
                }
            } else {
                this.isAutoCompany = false
            }
        }
    },

    updated() {
        if (this.isOnModal) {
            this.$nextTick(function () {
                // 주소 찾기 결과 값 로드
                // this.$EventBus.$on('close', (x) => {
                //     this.addressVisible = x
                // })
                this.$EventBus.$on('closeSearchZipCode', data => {
                    if (data) {
                        this.defaultAddress = data.address
                        this.zonecode = data.zonecode
                        console.log('data', data)
                        this.isOnModal = false

                        this.$EventBus.$off('close')
                        this.$EventBus.$off('closeSearchZipCode')
                    }
                })
            })
        }
    },

    mounted() {
        this.eapHopeDate = this.formatDate()
        this.getAddressAll()
        this.getCompanyListAll()
        this.getMetaList()
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getCustomerData(this.idx)
    },

    methods: {

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        getPersonData(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/client/person/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {
                        for (var item of Object.keys(res.data.personVO)) {
                            if (typeof this[item] !== 'undefined') {

                                this[item] = res.data.personVO[item]
                            }

                            // 이메일 맵핑
                            if (item === 'email') {
                                if (res.data.personVO[item].includes('@')) {
                                    var email = res.data.personVO[item].split('@')
                                    this.email1 = email[0]
                                    this.email2 = email[1]
                                }
                                continue
                            }

                            // 휴대폰 번호 매핑
                            if (item === 'phone') {
                                var phone = res.data.personVO[item].split('-')
                                if (phone.length === 3) {
                                    this.phone1 = phone[0]
                                    this.phone2 = phone[1]
                                    this.phone3 = phone[2]
                                }
                                continue
                            }

                        }
                        this.idxCrmPerson = res.data.personVO.idx
                    }
                })
        },

        getCustomerData(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/client/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {
                        for (var item of Object.keys(res.data.clientVO)) {
                            if (typeof this[item] !== 'undefined') {

                                this[item] = res.data.clientVO[item]
                            }
                        }

                        if (res.data.clientVO.idxCrmCompany !== '') {
                            this.idxCrmCompany = res.data.clientVO.idxCrmCompany
                        }

                        /* if(res.data.clientVO.email !== '') {
                            this.email1 = res.data.clientVO.email.split("@")[0]
                            this.email2 = res.data.clientVO.email.split("@")[1]
                        } */

                        // 휴대폰 번호 매핑
                        /* if (res.data.clientVO.phone !== null) {
                            var phone = res.data.clientVO.phone.split('-')
                            if (phone.length === 3) {
                                this.phone1 = phone[0]
                                this.phone2 = phone[1]
                                this.phone3 = phone[2]
                            }
                        } */

                        this.getCountyList()
                        this.getPersonData(res.data.clientVO.idxCrmPerson)
                        this.idxCrmClient = res.data.clientVO.idx

                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        putClientInfo() {

            let flag = false

            // 고객 자동완성 체크 부분
            if (!EmptyCheck(this.companyName, '기업을')) {
                return false
            } else {
                for (var company of this.companyList) {
                    if (company.name === this.companyName && company.idx === Number(this.idxCrmCompany)) {
                        flag = true
                        break;
                    }
                }
            }

            if (!flag) {
                alert('기업명을 다시 확인해주세요')
                return false
            }

            if (this.idxCrmCompany === -1) {
                alert('등록되지 않은 기업입니다.')
                return false
            }

            if (!EmptyCheck(this.name, '고객을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            if (phone === '') {
                alert('핸드폰정보를 입력해주세요')
                return false
            }

            if (!EmptyCheck(this.email1, '이메일을')) {
                return false
            }

            if (!EmptyCheck(this.email2, '이메일을')) {
                return false
            }

            if (!EmptyCheck(this.eapHopeArea, '희망지역을')) {
                return false
            }

            if (!EmptyCheck(this.eapHopeArea2, '희망 상세지역을')) {
                return false
            }

            if (this.idxCrmMetaType === -1) {
                alert('상담유형을 선택해주세요')
                return false
            }

            if (this.idxCrmMetaSubject === -1) {
                alert('상담주제를 선택해주세요')
                return false
            }

            if (!EmptyCheck(this.eapProcessStatus, '진행현황')) {
                return false
            }

            // 이메일을 전부 입력한경우에만 이메일 포맷으로 파싱
            if (this.email1 !== '' && this.email2 !== '') {
                var email = this.email1 + '@' + this.email2
            } else {
                var email = ''
            }
            var vo = {
                clientCode: this.clientCode,
                name: this.name,
                //email: this.email,
                email: email,
                phone: phone,
                gender: this.gender,
                birth: this.birth,
                idxCrmPerson: this.idxCrmPerson,
                idxCrmMetaRoute: this.idxCrmMetaRoute,
                idxCrmCompany: this.idxCrmCompany,
                idxCrmCenter: this.idxCrmCenter,
                idxCrmStaff: this.idxCrmStaff,
                idxCrmMetaType: this.idxCrmMetaType,
                idxCrmMetaSubject: this.idxCrmMetaSubject,
                eapHopeDate: this.formatDate(this.eapHopeDate),
                eapYn: this.eapYn,
                memo: this.memo,
                eapProcessStatus: this.eapProcessStatus,
                consCount: this.consCount,
                eapRel: this.eapRel,
                eapHopeArea: this.eapHopeArea,
                eapHopeArea2: this.eapHopeArea2

            }

            this.axios.put(`/api/v1/client/${this.idxCrmClient}`, vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('고객정보 수정이 완료되었습니다.')
                        this.$router.push('/origin/center_eap_manage')
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.typeList = res.data.typeList
                        this.subjectList = res.data.subjectList
                        this.routeList = res.data.routeList
                        this.dangerList = res.data.dangerList
                        this.proList = res.data.proList
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 소속리스트(기관리스트)
        getCompanyListAll() {
            this.axios.get('/api/v1/company/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.companyList = res.data.companyList
                    } else {
                        //alert('소속리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 시 목록
        getAddressAll() {
            this.axios.get('/api/v1/user/address/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.cityList = res.data.cityList
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 구 목록
        getCountyList() {

            if (this.eapHopeArea === '') {
                alert('지역을 먼저 선택하세요.')
                return;
            }

            let params = {
                city: this.eapHopeArea
            }
            this.axios.get('/api/v1/user/address', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.countyList = res.data.countyList
                    }
                })

        },

        // 센터 자동완성 입력 감지
        watchEvent(e, type) {

            if (type === 'counselor') {

                this.autoSearchCounselor = e.target.value

            } else {
                this.autoSearchCompany = e.target.value
            }

        },

        // 자동완성 끄기
        removeValue() {
            if (document.querySelector('.r').classList.contains('key')) {
                document.querySelector('.r').classList.remove('key');
                document.querySelector('.r li.sel').classList.remove('sel');
            }
            if (document.querySelector('.c').classList.contains('key')) {
                document.querySelector('.c').classList.remove('key');
                document.querySelector('.c li.sel').classList.remove('sel');
            }
        },

        // 자동완성 선택한 경우 센터명 변경
        changeValue(str, type, idx, centerIdx, centerName) {
            if (type === 'counselor') {
                console.log('idx', idx)
                console.log('centerIdx', centerIdx)
                console.log('centerName', centerName)
                this.isAutoCounselor = false
                this.staffName = str
                this.idxCrmStaff = idx
            } else {
                console.log('idx', idx)
                this.isAutoCompany = false
                this.companyName = str
                this.idxCrmCompany = idx
            }
        },

        // 자동완성 켜기 및 선택
        selectValue(keycode, str, idx, centerIdx, centerName) {
            if (this.isAutoCounselor) {
                const hasClass = document.querySelector('.r').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        console.log('여기탐')
                        const thisEl = document.querySelectorAll('.r li')[0];
                        document.querySelector('.r').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.r li:last-child');
                        const thisEl = document.querySelector('.r li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.r li')[0];
                    const thisEl = document.querySelector('.r li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'counselor', idx, centerIdx, centerName);
                }
            }

            if (this.isAutoCompany) {
                const hasClass = document.querySelector('.c').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.c li')[0];
                        document.querySelector('.c').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.c li:last-child');
                        const thisEl = document.querySelector('.c li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.c li')[0];
                    const thisEl = document.querySelector('.c li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'company', idx);
                }
            }
        },

        // 주소검색 모달 on
        onModal() {
            this.isOnModal = true
        },

        // 주소검색 모달 off
        closeModal() {
            this.isOnModal = false
        },

        // 등록
        submit() {

        },

        // 이전 페이지로 이동
        cancel() {
            history.back()
        }
    }
}
</script>

<style lang="scss">
#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .c {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}
</style>
